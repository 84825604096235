<template>
 <div class="ProfileBox">
    <h2>{{ this.risorse["IndirizziFatturazione"] }}</h2>
    <div class="Form">
        <div class="FormRow">
            <div class="FormBoxWide">
                <div class="UserInfo"><span>{{ this.risorse["Indirizzo"]}} 1</span> <span> {{this.anagrafica.Indirizzo}}, {{this.anagrafica.Cap}}  {{this.anagrafica.Citta}} {{this.anagrafica.Provincia ? ", "+this.anagrafica.Provincia : ""}} </span></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import {risorseMixin} from "@/mixin.js";

export default {
    name: "DatiFatturazione",
    data(){
        return  {
            anagrafica: this.$store.getters.getAnagraficaAzienda
        }
    },
    mixins:[risorseMixin]
}
</script>